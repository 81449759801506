/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Voltaire";
  font-size: 1rem;
  line-height: 1.6;
  background-color: #232327;
  color: var(--light-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}
.small-container {
  max-width: 40px;
  margin: auto;
  overflow: hidden;
  padding: 0 1rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.vertical-main-scroll-container {
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  margin-top: 5rem;
  margin-left: 35vw;
  overflow-x: auto;
  overflow-y: hidden;
/*   height: 100vh; */
  width: 65%;
}
.vertical-container {
  width: 70vw;
/*   height: 40vh; */
  float: 'right';
/*   font-size: 25px; */
/*   background: blue; */
  margin: 10px;
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
/*   line-height: 100px; */
}

.horizontal {
    writing-mode: horizontal-tb;
}
/* Text Styles*/
.x-large {
  font-size: 3rem;
  font-family: "Voltaire";
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1;
}

.mpr-header {
    text-align: 'justify';
    white-space: 'nowrap';
    font-family: "Voltaire";
    line-height: 0.9;
    display: 'inline-block';
    letter-spacing: '-5px';
    font-weight: 'bold';
    letter-spacing: -4px;
}

.gallery-item {
  width: 200px;
  padding: 5px;
}

.left-numbers {
    float: left;
/*     line-height: -3px; */
    font-size: 2.5rem;
    margin-bottom: 0px;
}
.numbers-text{
/*     float: left; */
    font-size: 1.8rem;
}
.dark {
    color: #4a2c75;
}
.title {
    font-size: 2rem;
    float: right;
}

ul.numbers-dot {
    max-width: 40em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
}

ul.numbers-dot li:before {
    float: left;
    width: 0;
    white-space: nowrap;
    color: white;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    content:". . . . . . . . . . . . . . . . . . . . "". . . . . . . . . . . . . . . . . . . . "". . . . . . . . . . . . . . . . . . . . "". . . . . . . . . . . . . . . . . . . . "
}
ul.numbers-dot span:first-child {
    padding-right: 0.1em;
    background: #ea9d28;
}
ul.numbers-dot span + span {
    float: right;
    padding-left: 0.1em;
    background: #ea9d28
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-space {
    padding-right: 25px;
}
.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  font-family: "Voltaire";
  height: 30px;
  padding: 0.8rem;
  font-size: 1.0rem;
  border: 2px solid #ea9d28;
  border-radius: 50px;
  background: transparent;
  color: white;
}

.form select {
  padding: 0 10px;
}

.form button {
    color: white;
}

::placeholder {
  opacity: 0.9;
}

.PhoneInput {
  width: 18.5rem;
  height: 30px;
}
.PhoneInput select {
    width: 38px;
}
.PhoneInputInput {
  width: 18.5rem;
  display: block;
  height: 30px;
  padding: 0.8rem;
  font-size: 1.0rem;
  border: 1px solid #ccc;
  border-radius: 50px;
}
.vertical-middle {
  vertical-align: middle;
}

.dropdown {
/*     width: 45%; */
    text-align: center;
    color: #000;
    z-index: 10;
}
.payment-form {
  width: 30vw;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.payment-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214npm install @fontsource/josefin-sans);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: transparent;
  font-family: "Voltaire";
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}


.form input[type='submit'],
button {
  background: transparent;
  font-family: "Voltaire";
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.form input[type='submit']:hover {
    color:#ea9d28;
}


.instagram {
    font-size: 1.25rem;
    position: absolute;

}
.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.date-from {
    float: left;
    width: 35%;
}
.date-from h4 {
    text-align: center;
}
.date-from iframe {
    width: 100%;
}
.date-to {
    float: left;
    width: 35%;
    text-align: center;

}
.clearfix {
    clear:both;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 0.3rem;
  text-align: center;
  font-size: 0.9rem;
}

.table th {
  background: var(--dark-color);
}

.table-center {
    float: left;

}
/* logo */
.logo {
    display: flex;
    z-index: 400;
    align-items: center;
    position: fixed;
    left: 20px;
    top: 10px;
    width: 20px;
    opacity: 0.4;
}
.logo:hover {
    opacity: 1;
}
.logo-image {
    width: 20rem;
}
.logo-login {
    color: #db992e;
    opacity: 0.2;
}
.logo-login:hover {
    opacity: 1;
}

.bottomright {
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 18px;
}

.languagemenu {
    z-index: 40;
    align-items: right;
    position: absolute;
    right: 25px;
    top: 10px;
    opacity: 1;
    display: flex;
    justify-content: center;

}

.languagemenu-icon {
  padding: 0px 2px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px #c3c5c7;
  text-align: center;
  line-height: 1;
}

.languagemenu-icon:hover {
    color: #ea9d28;
}

.languagemenu-menu {
  display: flex;
  flex-direction: column;
  top: 28px;
  right: 0;
  padding: 4px 0px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #c3c5c7;
}
.languagemenu-menu__row {
    display: flex;
    flex-direction: row;
  }

.languagemenu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 20px;
  width: 80px;
  color: #333537;
}

.languagemenu-item:hover {
    background-color: #f3f5f7;
}

.languagemenu-item__icon {
    font-size: 20px;
  }

.languagemenu-item__text {
    font-family: "Voltaire";
    font-size: 12px;
    font-style: normal;
  }

.example1-dropdown-menu {
	 display: flex;
	 flex-direction: column;
	 top: 28px;
	 right: 0;
	 padding: 8px 0px;
	 background-color: #fff;
	 border-radius: 3px;
	 box-shadow: 0px 0px 5px #c3c5c7;
}
 .example1-dropdown-menu-enter {
	 opacity: 0;
}
 .example1-dropdown-menu-enter-active {
	 opacity: 1;
	 transition: 0.2s ease-in;
}
 .example1-dropdown-menu-exit {
	 opacity: 1;
}
 .example1-dropdown-menu-exit-active {
	 opacity: 0;
	 transition: 0.2s ease-in;
}
 .example1-dropdown-menu-item {
	 display: flex;
	 flex-direction: row;
	 justify-content: center;
	 align-items: center;
	 padding: 6px 26px;
	 font-size: 12px;
	 color: #333537;
}
 .example1-dropdown-menu-item:hover {
	 background-color: #f3f5f7;
}
 .example1-dropdown-menu-item__spacer {
	 width: 6px;
}
 .example1-dropdown-menu-item__text {
	 padding: 0px 2px;
}

.img-column {
  flex: 33.33%;
  padding: 5px;
  justify-content: center;
  align-items: center;

}

.img-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-image {
  flex: 25%;
/*   padding: 5px; */
  justify-content: left;
  align-items: left;
}
.team-text {
  flex: 80%;
  height: 70px;
/*   padding: 5px; */
/*   justify-content: center; */
/*   align-items: center; */
  text-align: left;
/*   margin-left: 5px; */
  margin-top: auto;
}

.team-row {
  display: flex;
  justify-content: left;
  align-items: left;
}

.navmenu {
    z-index: 40;
    align-items: flex-end;
    justify-content: right;
    position: fixed;
    float: right;
    right: 20px;
    top: 30px;
    opacity: 0.9;
    display: flex;
    height: 30px;
    width: 80px;
    transform: translateY(0);
    transition: transform 0.3s ease;
    line-height: 1;
}

.navmenu-menu {
  display: flex;
  flex-direction: column;
  top: 28px;
  right: 0;
  padding: 8px 8px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #c3c5c7;
}

.navmenu-icon {
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0;
  padding: 0px 0px;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.navmenu-menu__row {
    display: flex;
    flex-direction: row;
  }

.navmenu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 20px;
  width: 80px;
  color: #333537;

}
.navmenu-item:hover {
    background-color: #f3f5f7;
}

.navmenu-item__icon {
    font-size: 20px;
  }

.navmenu-item__text {
    font-family: "Voltaire";
    font-size: 12px;
    font-style: normal;
  }

/* Style navigation menu links */
.topnav a {
  font-family: "Josefin Sans";
  color: white;
  padding: 14px 1px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  text-align: center;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: white;;
  color: black;
}

/* Style the active link (or home/logo) */
.active {
  background-color: #04AA6D;
  color: white;
}

.reqmenu {
    z-index: 40;
    align-items: right;
    position: absolute;
    right: 70px;
    top: 25px;
    opacity: 0.9;
    display: flex;
    justify-content: center;
}

.reqmenu-menu {
  display: flex;
  flex-direction: column;
  top: 28px;
  right: -150px;
  padding: 8px 8px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #3f454a;
}
.reqmenu-menu__row {
    display: flex;
    flex-direction: row;
  }

.reqmenu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px;
  height: 60px;
  width: 80px;
  color: #333537;
}
.reqmenu-item:hover {
    background-color: #f3f5f7;
}

.reqmenu-item__icon {
    font-size: 20px;
  }

.reqmenu-item__text {
  font-family: "Voltaire";
  font-size: 12px;
  font-style: normal;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  background-color: #fff;
  color: #333;
}

/* Navbar */
.navbar {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0 0 20px grey, 0 0 1px white;
  display: flex;
  z-index: 40;
  position: fixed;
  top: 10px;
  padding-left: 40px;
  color: transparent;
  transform: translateY(0);
  transition: transform 0.3s ease;
  line-height: 1;
  overflow: visible;
  background-color: transparent;
  width: 100%;
  text-align: center;
}

.navbar .navitem {
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    transition: background-color .2s ease-out;

}

.white {
    color: white;
}

.navbar a {
  color: white;
}


.navbar.hidden {
  transform: translateY(-250%);
}
.navmenu.hidden {
  transform: translateY(-200%);
}

/* Landing Page */
.landing {
  margin: auto;
  display: flex;
  flex-direction: column;
/*   background: url('./img/background.jpg') no-repeat center center/cover; */
  height: 80vh;
  z-index: 5;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 5;
}
.landing-arrow {
  color: #db992e;
  padding-top: 400px;
  position: absolute;
  right: 5rem;
  z-index: 5;
}
.alice-carousel ul li img{
  height: 90vh;
  width: 100%;
  object-fit: cover;
}
.landing-nav {
  padding-top: 5000px;
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 5;
}

.gold {
    color: #ea9d28;
}

.gold-link {
    color: #ea9d28;
}
.gold-link:hover {
    color: white;
}

.text-gold {
    color: #ea9d28;
    font-family: "Voltaire";
    font-size: 2.5rem;
    line-height: 1.4;
    cursor: pointer;
}

.line-gold:before {
   background-color: #ea9d28;
   content: "";
   display: inline-block;
   height: 3px;
   position: relative;
   vertical-align: middle;
   width: 8rem;
   margin-right: 0.5rem;
}
.line-purple:before {
   background-color: #492b74;
   content: "";
   display: inline-block;
   height: 3px;
   position: relative;
   vertical-align: middle;
   width: 8rem;
   margin-right: 0.5rem;
}

.pink {
    color: #ed008c;
}
.pink-link {
    color: #ed008c;
}
.pink-link:hover {
    color: white;
}

.line-pink:before {
   background-color: #ed008c;
   content: "";
   display: inline-block;
   height: 3px;
   position: relative;
   vertical-align: middle;
   width: 8rem;
   margin-right: 0.5rem;
}

.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
  transition: transform 0.5s;
  will-change: transform;
}

.landing-container {
    min-height: 30vh;
}

.footer-container {
  letter-spacing: 2.5px;
/*   font-size: 0.6rem; */
/*   line-height: 3; */
  font-weight: 500;
  color: #ea9d28;
}

.footer-container a {
  color: #ea9d28;
}

.footer-container a:hover {
  color: white;
}

.inner-landing-container {
  max-width: 25rem;
  margin: auto;
  overflow: visible;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.wide-landing-container {
  max-width: 40rem;
  margin: auto;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20vh;
  margin-top: 20vh;
  overflow: visible;
}

.html {
    scroll-snap-type: y mandatory;
    overflow: auto;
    height: 100vh;
}

.donate-button {
    text-align: center;
}

.donate-button p {
    color: #ea9d28;
}

.donate-button h1 {
    line-height: 1;
}

.donate-button button:hover {
    color: #ea9d28;
}

.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp > div,
.profile-edu > div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp > div:last-child,
.profile-edu > div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo > div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github > div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.rdtPicker {
    color: white;
    background-color: black;
    border-right: 1px solid #d4d7d9;
}

.rdtPicker:hover {
/*     background-color: yellow; */
}

.link {
  color: #0000ff;
  font-weight: bold;

}

.link:hover {
   color: #aaaa7f;
}
.team-container {
/*     margin: auto; */
/*     align-items: center; */
/*     justify-items: center; */
/*     width: 100%; */
/*     position: absolute; */
  display: grid;
  grid-template-columns: 1.3fr 0.7fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Team-list Profile";
}
.Team-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.6fr 0.9fr 1.5fr;
  gap: 0px 0px;
  grid-template-areas:
    "Title Description Description"
    "List List List"
    "List List List";
  grid-area: Team-list;
}
.Description { grid-area: Description; }
.Title { grid-area: Title; }
.List {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0.5fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "ic1 name1 ic2 name2"
    "ic3 name3 ic4 name4"
    "ic5 name5 ic6 name6"
    "ic7 name7 ic8 name8"
    "ic9 name9 ic10 name10"
    "ic11 name11 ic12 name12";
  grid-area: List;
}
.name1 { grid-area: name1; }
.name2 { grid-area: name2; }
.name3 { grid-area: name3; }
.name4 { grid-area: name4; }
.name5 { grid-area: name5; }
.name6 { grid-area: name6; }
.name7 { grid-area: name7; }
.name8 { grid-area: name8; }
.name9 { grid-area: name9; }
.name10 { grid-area: name10; }
.name11 { grid-area: name11; }
.name12 { grid-area: name12; }
.ic7 { grid-area: ic7; }
.ic8 { grid-area: ic8; }
.ic9 { grid-area: ic9; }
.ic10 { grid-area: ic10; }
.ic11 { grid-area: ic11; }
.ic12 { grid-area: ic12; }
.ic6 { grid-area: ic6; }
.ic5 { grid-area: ic5; }
.ic4 { grid-area: ic4; }
.ic3 { grid-area: ic3; }
.ic2 { grid-area: ic2; }
.ic1 { grid-area: ic1; }
.Profile { grid-area: Profile; }




.fc {
/*   background-color: #dedede; */
  padding: 8px 8px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #969899;
  color: #000000;
}
.fc-daygrid-day-number {
    color: #487f8d;
}

/* .fc-timegrid-axis-cushion { */
/*     color: #000000; */
/* } */

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
padding-left: 1px;
padding-right: 1px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
width: 55px;
}

.react-datepicker__time-container {
width: 55px
}
/* Spinner */
@keyframes ldio-b56w8yib6qp {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-b56w8yib6qp div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-b56w8yib6qp linear 1.408450704225352s infinite;
  background: #ea9d28;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}.ldio-b56w8yib6qp div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.2910798122065725s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.1737089201877933s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.056338028169014s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.9389671361502346s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.8215962441314553s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.7042253521126759s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5868544600938966s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4694835680751173s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.35211267605633795s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.23474178403755866s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.11737089201877933s;
  background: #ea9d28;
}.ldio-b56w8yib6qp div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ea9d28;
}
.loadingio-spinner-spinner-mobvtfhpq {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-b56w8yib6qp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-b56w8yib6qp div { box-sizing: content-box; }
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 16px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 16px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.about {
    margin-bottom: 5vh;
}

.about button {
    display: inline-block;
    letter-spacing: 2.5px;
    font-size: 1rem;
    text-align: justify;
    font-weight: 500;
    color: #ea9d28;
}

.about button:hover {
    color: white;
}

.request p {
    font-size: 1.2rem;
    width: 100%;
}

.press button {
    letter-spacing: 3px;
    font-size: 1rem;
    font-weight: 500;
    color: #ea9d28;
}

.press h3 {
    font-weight: 700;
    font-size: 1.2rem;
}

.request button {
    display: inline-block;
    letter-spacing: 1px;
    line-height: 1.5;
    font-size: 1rem;
    text-align: left;
    font-weight: 500;
    color: white;
}

.request button:hover {
    color: #ea9d28;
}

.team {
    margin-bottom: 5vh;
}

.team h3 {
    line-height: 1.2;
    letter-spacing: 0.3px;
    font-size: 1.3rem;
    font-weight: bold;
    display:table-caption;
    text-align: left;
}

.team h4 {
    font-size: 0.5rem;
    float: left;
    color: #ea9d28;
    white-space: pre-wrap;

}

.team button {
    display: inline-block;
    letter-spacing: 2.5px;
    font-size: 1rem;
    text-align: justify;
    font-weight: 500;
    color: white;
    width: 100%;
    padding: 5px 0px;
}
.team button:hover {
    color: #ea9d28;
}
.team, button img {
    filter: contrast(80%);
}
.team, button:hover img:hover {
    transition: all 1.2s ease;
    filter: contrast(100%);
}
.team table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
}

.impact {
  color: #482b72;
}

.impact button {
  color: #482b72;
  margin-left: auto;
  margin-right: auto;
  width: 8em;
  display: block;
}

.impact button:hover {
  color: white;
}

/* The dropdown container */
.dropdown {
  padding: 5px 1rem;
  margin: auto;
/*   padding-left: 2.5rem; */
}

/* Dropdown button */
.dropdown .dropbtn {
/*   text-shadow: 0 1px white; */
  font-size: 0.8rem;
  border: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  color: #ea9a27
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  font-size: 0.7rem;
  position: absolute;
  background-color: transparent;
  opacity: .95;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #bcbcbc;
  padding: 12px 0px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    margin-top: 8rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 2.5rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }


  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
/*     margin-bottom: 0.2rem; */
  }

  .post {
    grid-template-columns: 1fr;
  }
  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

}
